/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import Typography from "components/Typography";
import '../../App.css';

// Material Kit 2 React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
// import Container from '@mui/material/Container';

import yellow from "assets/yellow.jpg"
import pink from "assets/pink.jpg"
import BTS from "assets/BTS.jpg"
import Gift from "assets/gifts.jpg"
import GIGI from "assets/GIGI.jpg"
import groom from "assets/groom.jpg"
// import black from "assets/black.jpg"


const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    // height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: yellow,
    title: 'Golden ivory celebration',
    width: '40%',
  },
  {
    url: pink,
    title: 'Blush ivory celebration',
    width: '20%',
  },
  {
    url: BTS,
    title: 'Paradise with BTS',
    width: '40%',
  },
  {
    url: GIGI,
    title: 'Twilight mist',
    width: '38%',
  },
  {
    url: groom,
    title: 'Groom Dream',
    width: '38%',
  },
  {
    url: Gift,
    title: 'Big Gifts',
    width: '24%',
  },
  // {
  //   url: 'https://images.unsplash.com/photo-1506941433945-99a2aa4bd50a?auto=format&fit=crop&w=400',
  //   title: 'Walking',
  //   width: '40%',
  // },
  // {
  //   url: groom,
  //   title: 'Fitness',
  //   width: '20%',
  // },
  // {
  //   url: 'https://images.unsplash.com/photo-1518136247453-74e7b5265980?auto=format&fit=crop&w=400',
  //   title: 'Reading',
  //   width: '40%',
  // },
];

function Presentation() {
  return (
    <>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      /> */}
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Rakesh Raju Photography{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Turn your Happy moments into images
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
      

        <MKBox component="section">
        <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center",  mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Top-natch creativity"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Our Album Works
          </MKTypography>
          <MKTypography variant="body1" color="text">
          Create a custom photo album to 
            <br /> celebrate your wedding or other milestone moments.
          </MKTypography>
        </Grid>
      </Container>
      {/* <Container sx={{ mt: 6 }}>{}</Container> */}
    </MKBox>

   
        <div class='album'>

<div class="imgLoader"></div>

<div class="container">
  <div class="book">
    <div class="gap"></div>
    <div class="pages">
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
      <div class="page"></div>
    </div>
    <div class="flips">
      <div class="flip flip1">
        <div class="flip flip2">
          <div class="flip flip3">
            <div class="flip flip4">
              <div class="flip flip5">
                <div class="flip flip6">
                  <div class="flip flip7"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<Team/>


<Pages />
       
        {/* <Download />  */}
        <Container component="section" >
      {/* <Typography variant="h4" marked="center" align="center" component="h2">
        For all tastes and all desires
      </Typography> */}
      <MKBox component="section">
      <Container>
        <Grid
          container
          item
          // xs={6}
          // lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: "auto", mx: "auto", px: 0.30 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Infinite combinations"
            container
            sx={{ mb: 2 }}
          /> */}

          <MKTypography variant="h2">Buttercup</MKTypography>
          <MKTypography variant="h2" color="info" textGradient >
          Event Management
          </MKTypography>
          {/* <MKTypography variant="body1" color="text" mb={2}>
          Creating Unforgettable Celebrations with Style and Elegance.
          </MKTypography>


          <MKTypography variant="h2" fontWeight="bold">
          Buttercup Event Management
          </MKTypography> */}
          <MKTypography variant="body1" color="text">
          Creating Unforgettable Celebrations with Style and Elegance.
          </MKTypography>
        </Grid>
      </Container>
      {/* <Container sx={{ mt: 6 }}>{}</Container> */}
    </MKBox>
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>

    </Container>

    {/* <BuiltByDevelopers/> */}
        <MKBox pt={1} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography> */}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/rakeshraju.photography._?igsh=dXFqam1zY3c3cW40"
                  target="_blank"
                  color="red"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;Instagram
                </MKSocialButton>
                {/* <MKSocialButton
                  component="a"
                  href=""
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;facebook
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href=""
                  target="_blank"
                  color="whats.app"
                >
                  <i className="fab fa-whatsapp" />
                  &nbsp;what's app
                </MKSocialButton> */}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
