// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
// import { Instagram, WhatsApp } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Rakesh Raju Photography",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/rakeshraju.photography._?igsh=dXFqam1zY3c3cW40",
    },
  ],
  menus: [
    {
      name: "company",
      items: [{ name: "RR Photography", href: "" }],
    },
    {
      name: "Email Address",
      items: [{ name: "rakeshraju9840@gmail.com", href: "" }],
    },
    {
      name: "Contact Us",
      items: [
        {
          name: "+91 6383117404",
          href: "",
        },
        // {
        //   name: "knowledge center",
        //   href: "https://www.creative-tim.com/knowledge-center",
        // },
        // {
        //   name: "custom development",
        //   href: "https://services.creative-tim.com/",
        // },
        // {
        //   name: "sponsorships",
        //   href: "https://www.creative-tim.com/sponsorships",
        // },
      ],
    },
    {
      name: "Address",
      items: [
        {
          name: "39, Amirta Villa, 1st Floor, 7th Main Rd, Ram Nagar, Velachery, Chennai, Tamil Nadu 600042",
          href: "",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Rakesh Raju Photography by{" "}
      <MKTypography
        component="a"
        href="https://zigainfotech.com/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Ziga Infotech
      </MKTypography>
    </MKTypography>
  ),
};
