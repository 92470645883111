/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Why
                    <br />
                    Choose Us?
                  </>
                }
                description="Our team comprises seasoned photographers with a keen eye for detail and a passion for excellence"
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Explore the beauty of nature through our stunning landscape and wildlife photography, showcasing the world in its most raw and breathtaking form"
                action={{
                  type: "internal",
                  // route: "/sections/page-sections/page-headers",
                  label: "BOOk NOW",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Book Your Session Today!"
                  description="Ready to make memories that last a lifetime? Contact us now to schedule your session or event coverage. Follow us on social media for the latest updates and special offers."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                 icon="devices"
                 title="Discover the Art of Storytelling Through the Lens"
                 description="We believe that every moment has a story to tell. Our team of passionate photographers is dedicated to capturing the essence of your special occasions with creativity and precision."
               />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Affordable Packages"
                  description=" Our competitive pricing ensures you get the best value without compromising on quality."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                
                icon="flip_to_front"
                title="Personalized Approach"
                description="We work closely with you to understand your vision and bring it to life through our lenses."
             
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
