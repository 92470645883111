/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import image1 from "../../../../assets/images/12.jpg"
import image2 from "../../../../assets/images/13.jpg"
import image3 from "../../../../assets/images/133.jpg"
import image4 from "../../../../assets/images/08.jpg"
import image5 from "../../../../assets/images/05.jpg"
import image6 from "../../../../assets/images/09.jpg"


export default [
  {
    image: image1,
    // name: "About Us Page",
    // route: "/pages/landing-pages/about-us",
  },
  {
    image: image2,
    // name: "Contact Us Page",
    // route: "/pages/landing-pages/contact-us",
  },
  {
    image: image3,
    // name: "Sign In Page",
    // route: "/pages/authentication/sign-in",
  },
  {
    image: image4,
    // name: "Author Page",
    // route: "/pages/landing-pages/author",
  },
  {
    image: image5, 
    // name: "Sign In Page",
    // route: "/pages/authentication/sign-in",
  },
  {
    image: image6,
    // name: "Author Page",
    // route: "/pages/landing-pages/author",
  },
];
