/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Traditional from "assets/images/trad.jpg";
import kitchen from "assets/images/chicken.jpg";
import music from "assets/images/353836924_640225961033936_7266399796381911216_n.jpeg";
import Libary from "assets/images/344582325_907808050503852_5102757600634635640_n.jpeg";
import mom from "assets/images/298749679_757965518591161_8689354493030443072_n.jpeg";
// import newbron from "assets/images/263534422_291903389516180_8699613271362963833_n.jpg";
import moon from "assets/images/245393602_117450237359577_8610169909008992794_n.jpg";
import jai from "assets/images/290978208_1081739149216663_6086317583172500028_n.jpeg";
import riyaz from "assets/images/289586347_757878288680308_3293636258093909862_n.jpeg";
import hassan from "assets/images/269946595_261433399414054_198658940405089547_n.jpg";
import sugan from "assets/images/279000877_555518805906775_1362413627941480574_n.jpeg";
import jaigan from "assets/images/291124609_1898537213663338_4682641282395461958_n.jpeg";
import gracy from "assets/images/IMG_9403.JPG";
import balaji from "assets/images/405200753_917823616574523_980599765376287310_n.jpeg";
import balaji2 from "assets/images/IMG_3886.JPG";
import ramki from "assets/images/IMG_0398.jpg";
import puberty1 from "assets/images/puberty1.jpg";
import Leaves from "assets/images/IMG_0060.JPG";
export default [
  {
    title: "Baby Shoot",
    description: "𝐂𝐡𝐨𝐨𝐬𝐞 𝐲𝐨𝐮𝐫 𝐨𝐰𝐧 𝐭𝐡𝐞𝐦𝐞 𝐚𝐧𝐝 𝐜𝐨𝐬𝐭𝐮𝐦𝐞 𝐟𝐨𝐫 𝐚 𝐮𝐧𝐢𝐪𝐮𝐞 𝐚𝐧𝐝 𝐩𝐞𝐫𝐬𝐨𝐧𝐚𝐥𝐢𝐳𝐞𝐝 𝐞𝐱𝐩𝐞𝐫𝐢𝐞𝐧𝐜𝐞!",
    items: [
      {
        image: Traditional,
        name: "Traditional Theme",
        // count: 10,
        // route: "/sections/page-sections/page-headers",
      },
      {
        image: kitchen,
        name: "kitchen Theme",
        // count: 14,
        // route: "/sections/page-sections/features",
      },
      {
        image: Libary,
        name: "Library Theme",
        // count: 1,
        // pro: true,
      },
      {
        image: music,
        name: "Musical Theme",
        // count: 8,
        // pro: true,
      },
      {
        image: mom,
        name: "Moms Love",
        // count: 11,
        // pro: true,
      },
      // {
      //   image: newbron,
      //   name: "New bron",
      //   // count: 11,
      //   // pro: true,
      // },
      {
        image: moon,
        name: "Moon Light",
        // count: 6,
        // pro: true,
      },
      // {
      //   image: `${imagesPrefix}/stats.jpg`,
      //   name: "Stats",
      //   // count: 3,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/call-to-action.jpg`,
      //   name: "Call to Actions",
      //   // count: 8,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/projects.jpg`,
      //   name: "Applications",
      //   // count: 6,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/logo-area.jpg`,
      //   name: "Logo Areas",
      //   // count: 4,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/footers.jpg`,
      //   name: "Footers",
      //   // count: 10,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/general-cards.jpg`,
      //   name: "General Cards",
      //   // count: 9,
      //   // pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/content-sections.jpg`,
      //   name: "Content Sections",
      //   // count: 8,
      //   // pro: true,
      // },
    ],
  },
  {
    title: "Indoor Studio",
    description: "Explore our Indoor Studio, offering a variety of backdrops to suit your style, the option to elevate your sessions with smoke effects, and a spacious seating area for 20+ individuals, perfect for group photoshoots and creative collaborations.",
    items: [
      {
        image: jai,
        name: "Lights Setup With Smoke",
        // count: 4,
        // route: "/sections/navigation/navbars",
      },
      {
        image: hassan,
        name: "Newspaper",
        // count: 3,
        // route: "/sections/navigation/pagination",
      },
      {
        image: riyaz,
        name: "Royal Seater",
        // count: 2,
        // route: "/sections/navigation/nav-tabs",
      },
    ],
  },
  {
    title: "Maternity",
    description: "Immerse yourself in our Maternity Studio, featuring a diverse selection of backdrops to match your style, capturing the essence of your baby shower events and ensuring the mom to be feels like the star of a special occasion",
    items: [
      {
        image: sugan,
        name: "Baby shower",
        // count: 6,
        // pro: true,
      },
      {
        image: gracy,
        name: "Mom to be",
        // count: 8,
        // pro: true,
      },
      {
        image: jaigan,
        name: "Studio BackDrop",
        // count: 3,
        // route: "/sections/input-areas/forms",
      },
      // {
      //   image: `${imagesPrefix}/inputs.jpg`,
      //   name: "Inputs",
      //   count: 6,
      //   route: "/sections/input-areas/inputs",
      // },
    ],
  },
  {
    title: "Pre Wedding",
    description: "Step into our PreWedding Studio, where a diverse array of enchanting backdrops awaits to complement your style, capturing the essence of your love story and turning each moment into a cherished memory. As we embark on this journey outdoors, let nature be the backdrop to the start of your love story, and together, we'll freeze these moments in time. Transforming instants into cherished memories, frame by frame. Let's craft enchanting moments together, capturing the magic of new love stories.",
    items: [
      {
        image: balaji2,
        name: "Couple Goals",
        // count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: balaji,
        name: "Sun set",
        // count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: ramki,
        name: "Surprise",
        // count: 2,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      // {
      //   image: `${imagesPrefix}/modals.jpg`,
      //   name: "Modals",
      //   // count: 5,
      //   // route: "/sections/attention-catchers/modals",
      // },
    ],
  },
  {
    title: "Puberty ceremony",
    description: "Embrace the essence of puberty photography with us. From serene landscapes to urban streets, our diverse backdrops capture your journey. Let nature be your canvas as we freeze moments, celebrating your transition into adulthood",
    items: [
      {
        image: puberty1,
        name: "Manjal neeraattu vizha",
        // count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: Leaves,
        name: "Traditional culture",
        // count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      // {
      //   image: ramki,
      //   name: "Surprise",
      //   // count: 2,
      //   // route: "/sections/attention-catchers/tooltips-popovers",
      // },
      // {
      //   image: `${imagesPrefix}/modals.jpg`,
      //   name: "Modals",
      //   // count: 5,
      //   // route: "/sections/attention-catchers/modals",
      // },
    ],
  },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
